import * as types from '../../mutation-types';
import Vue from 'vue';

const state = {
  isOpenDialPad: false,
};

export const getters = {
  getIsOpenDialPad: $state => {
    return $state.isOpenDialPad;
  },
};

export const actions = {
  openDialPad({ commit }) {
    commit(types.default.OPEN_DIAL_PAD);
  },
  closeDialPad({ commit }) {
    commit(types.default.CLOSE_DIAL_PAD);
  },
};

export const mutations = {
  [types.default.OPEN_DIAL_PAD]: $state => {
    Vue.set($state, 'isOpenDialPad', true);
  },
  [types.default.CLOSE_DIAL_PAD]: $state => {
    Vue.set($state, 'isOpenDialPad', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
