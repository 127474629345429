<template>
  <div :class="avatarClass" :style="style" aria-hidden="true">
    <slot>
      <fluent-icon v-if="isSentByBot" icon="bot" size="12" />
      <span v-else>{{ userInitial }}</span>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    username: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    style() {
      return {
        fontSize: `${Math.floor(this.size / 2.5)}px`,
      };
    },
    userInitial() {
      const parts = this.username.split(/[ -]/);
      let initials = parts.reduce((acc, curr) => acc + curr.charAt(0), '');

      if (initials.length > 2 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '');
      }
      initials = initials.substring(0, 2).toUpperCase();

      return initials;
    },
    isSentByBot() {
      return this.username === 'Bot';
    },
    avatarClass() {
      const avatarColor = this.isSentByBot
        ? 'avatar-bot-color'
        : 'avatar-color';
      return `avatar-container ${avatarColor}`;
    },
  },
};
</script>

<style scoped>
@tailwind components;
@layer components {
  .avatar-color {
    background-image: linear-gradient(to top, var(--w-75), var(--w-25));
    @apply text-woot-600;
  }

  .avatar-bot-color {
    background-image: linear-gradient(to top, var(--blue-100), var(--blue-50));
    @apply text-blue-400;
  }

  .dark .avatar-color {
    background-image: linear-gradient(to top, var(--w-500), var(--w-400));
    @apply text-woot-50;
  }

  .dark .avatar-bot-color {
    background-image: linear-gradient(to top, var(--blue-500), var(--blue-400));
    @apply text-blue-50;
  }
}
.avatar-container {
  @apply flex leading-[100%] font-medium items-center justify-center text-center cursor-default;
}
</style>
