<template>
  <div>
    <button
      class="text-slate-600 mb-4 dark:text-slate-100 w-10 h-10 my-1 p-0 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
      :class="{
        'bg-woot-50 dark:bg-slate-800 text-woot-500 hover:bg-woot-50':
          isOpenDialPad,
      }"
      @click="toggleDialPad"
    >
      <fluent-icon
        icon="dial-pad"
        :class="{
          'text-woot-500': isOpenDialPad,
        }"
      />
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isOpenDialPad: 'dialpad/getIsOpenDialPad',
    }),
  },
  methods: {
    toggleDialPad() {
      if (this.isOpenDialPad) {
        this.$store.dispatch('dialpad/closeDialPad');
      } else {
        this.$store.dispatch('dialpad/openDialPad');
      }
    },
  },
};
</script>
